import { get } from "./Api";

export default {
  getEvents(): Promise<IEvents> {
    return get("/event_zone/get_event_zone_list");
  },

  getCoupons(): Promise<ICoupon[]> {
    return get("/coupon/get_couponbook_list");
  },
};

export interface IEvents {
  close: ICloseEvents;
  open: IEvent[];
}

interface ICloseEvents {
  current_page: number;
  data: IEvent[];
}

export interface IEvent {
  id: string;
  list_name: string; // "머스탱을 내 차처럼!"
  list_time: string; // "2020-04-24~2020-05-31"
  list_comment: string; // "쏘카플랜 프리미엄 차량 특별전"
}

export interface ICoupon {
  id: string;
  title_comment: string; // "1박2일 36,000원~"
  activate_start_at: string; // "2018-01-05 10:00:10"
  activate_end_at: string; // "2020-09-28 19:00:00"
  condition_comment: string; // "<strong style="color:#f75f50;">쏘스타 전용<br>기존 쿠폰북에서 추가 5% 할인 </strong>"
}
