import React from "react";

const Service = () => {
  return (
    <>
      <section className="head" id="service-head">
        <div className="cent">
          <h1>쏘카 서비스 안내</h1>
          <h2>
            나에게 꼭 맞는 방법으로
            <br className="pbr" /> 다양한 쏘카의 서비스를 이용해보세요.
          </h2>
        </div>
      </section>

      <section className="content" id="service-cont">
        <div className="wrap">
          <section className="">
            <article className="main">
              <h2 className="mv">
                차가 필요한
                <br /> 모든 순간, 쏘카
              </h2>
              <p className="mv">
                언제 어디서나 필요한 만큼
                <br />
                쏘카로 쉽고 편한
                <br />
                카셰어링을 경험해보세요.
              </p>
            </article>
            <a href="/guide" className="btn btnu btna mv3">
              이용 방법에 대해 알아보세요
            </a>
          </section>
          <section className="business">
            <article>
              <h2 className="mv">
                일하는 사람들의
                <br />
                이동 파트너,
                <br />
                쏘카비즈니스
              </h2>
              <p className="mv">
                법인 전용 서비스로
                <br />
                임직원은 더 편리하게 이용하고,
                <br />
                회사의 불필요한 비용은 줄여보세요.
              </p>
            </article>

            <img
              alt="쏘카차량을 운전중인 사람"
              src="../images/usp_service1.jpg"
              className="left mv"
            />
            <a
              href="https://business.socar.kr"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btnu btna mv3">
              쏘카비즈니스 바로가기
            </a>
          </section>
          <section className="plan">
            <article>
              <h2 className="mv">
                쏘카를 한 달씩
                <br />
                내 차처럼,
                <br />
                쏘카플랜
              </h2>
              <p className="mv">
                월 단위 카셰어링 서비스를
                <br />
                온라인으로 간편하게 계약하고,
                <br />
                필요한 기간만큼 자유롭게 이용하세요.
              </p>
            </article>

            <img
              alt="주차되어있는 쏘카차량을 가지러 가는 사람"
              src="../images/usp_service2.jpg"
              className="left mv"
            />
            <a
              href="https://plan.socar.kr"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btnu btna mv3">
              쏘카플랜 바로가기
            </a>
          </section>
        </div>
      </section>
    </>
  );
};

export default Service;
